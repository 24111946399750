import React from 'react'
import '../assets/css/header.css'
const Header = () => {
  return (
    <header className={"header"}>
        <h1>BLOG ACEFF</h1>
        <p>Fique por dentro de todas nossas atividades e eventos.</p>
    </header>
  )
}

export default Header